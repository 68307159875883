@supports (display: grid) {
  .feature-news-posts {
    position: relative;
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      "p1 p1"
      "p2 p2"
      "p3 p3"
      "p4 p4"
      "p5 p5"
      "p6 p6"
      "p7 p7";
  }

  .feature-news-posts li {
    list-style: none;
    height: initial;
  }

  .feature-news-posts li:first-child {
    grid-area: p1;
  }

  .feature-news-posts li:nth-child(2) {
    grid-area: p2;
  }

  .feature-news-posts li:nth-child(3) {
    grid-area: p3;
  }

  .feature-news-posts li:nth-child(4) {
    grid-area: p4;
  }

  .feature-news-posts li:nth-child(5) {
    grid-area: p5;
  }

  .feature-news-posts li:nth-child(6) {
    grid-area: p6;
  }

  .feature-news-posts li:nth-child(7) {
    grid-area: p7;
  }

  @media (min-width: 320px) {
    .feature-news-posts {
      grid-template-areas:
        "p1 p1"
        "p2 p3"
        "p4 p4"
        "p5 p5"
        "p6 p6"
        "p7 p7";
    }
  }

  @media (min-width: 37.5rem) {
    .feature-news-posts {
      gap: 30px 20px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-areas:
        "p1 p1 p1 p2"
        "p1 p1 p1 p3"
        "p4 p5 p6 p7";
    }
  }

  @media (min-width: 800px) {
    .feature-news-posts {
      gap: 30px;
    }
  }

  @media (min-width: 1200px) {
    .feature-news-posts {
      row-gap: 1.25em;
      column-gap: 40px;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      grid-template-areas:
        "p1 p1 p1 p2 p4"
        "p1 p1 p1 p2 p5"
        "p1 p1 p1 p3 p6"
        "p1 p1 p1 p3 p7";
    }
  }
}
