.btn {
  padding: 13px 2.375rem;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
  transition: ease 0.4s;
  margin-right: 12px;
  margin-bottom: 10px;
  max-height: 50px;

  &:hover {
    transition: ease 0.4s;
  }
}

.btn:only-child {
  margin-right: 0;
  margin-bottom: 0;
}

.btn:last-child {
  margin-right: 0;
}
