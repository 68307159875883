.specTable {
  width: 100%;
}

.desktop {
  display: none;
}

.mobile {
  display: flex;
  flex-direction: column;
}

.specItem {
  padding: 10px 0;
  @apply border-b border-solid border-b-pn-black-base;
}

.label {
  font-weight: bold;
}

.value {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .desktop {
    display: table;
    /* Account for 40px padding each side... */
    width: calc(100% + 80px);
    text-align: left;
    border-collapse: separate;
    border-spacing: 40px 20px;
    margin-left: -40px;
  }

  .desktop thead th {
    @apply text-h2 font-roboto-serif;
  }

  .desktop tbody tr th,
  .desktop tbody tr td {
    @apply border-b border-solid border-b-pn-black-base pb-5 align-top;
  }

  .mobile {
    display: none;
  }
}
