.card {
  position: relative;
  max-width: 100%;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  transition: ease-out 0.15s;

  /* as well as wanting the card flex, we will set the body flex
       so we have more flexibility with ordering */
  .card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.h-card {
    margin-bottom: 4%;

    &.f-row {
      .card-body {
        flex: 0 0 65%;
        flex-wrap: wrap;
      }
    }

    &.over {
      .media {
        position: absolute;
        height: 100%;
        max-width: 100%;
        padding-bottom: 0 !important;

        .media-link {
          position: absolute;
          padding-bottom: 0 !important;
          width: 100%;
        }
      }

      .card-body {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 20;
        min-height: 200px;
      }

      [style*="color: #ffffff;"] > *,
      [style*="color: rgb(255, 255, 255);"] > * {
        color: #ffffff;
      }
    }
  }
}

.v-card,
.h-card {
  flex-direction: column;

  /* as well as wanting the card flex, we will set the body flex so we have more flexibility with ordering */
  .card-body {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
}

.h-card .media img {
  @apply rounded-[3px];
}

// .card .post-meta > * + * {
//   margin-inline-start: 15px;
// }

.card-meta {
  // margin-top: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 9px;
  align-items: center;
  justify-content: space-between;
  line-height: 1.2;
  gap: 10px;

  * {
    margin-bottom: 0 !important;
  }

  .html svg {
    width: 100px;
    height: 100px;
  }
}

.card-body .html {
  height: auto;

  > i.fas,
  i.far,
  i.fal {
    font-size: 45px;
    font-weight: 100;
  }

  svg {
    width: 100px;
    height: 100px;
  }
}

.card-body * {
  max-width: 100%;
}

/* Remove margin bottoms */
.stack {
  .card:nth-child(1n + 1):nth-last-child(-n + 1),
  .card:nth-child(1n + 1):nth-last-child(-n + 1) ~ .card {
    margin-bottom: 0 !important;
  }
}

.media {
  position: relative;
  display: block;
  width: 100%;
}

.media-link {
  display: block;
  // height: 100%;
}

.h-card .media {
  align-self: stretch;
}

.xs-ratio {
  // padding-bottom: 33.3% !important;
  aspect-ratio: 1 / 0.33;
}
.sm-ratio {
  // padding-bottom: 45% !important;
  aspect-ratio: 1 / 0.45;
}
.wide-ratio {
  // padding-bottom: 56% !important;
  aspect-ratio: 16 / 9;
}
.md-ratio {
  // padding-bottom: 66% !important;
  aspect-ratio: 3 / 2;
}
.lg-ratio {
  // padding-bottom: 75% !important;
  aspect-ratio: 4 / 3;
}
.sq-ratio {
  // padding-bottom: 100% !important;
  aspect-ratio: 1 / 1;
}
.xl-ratio {
  // padding-bottom: 112% !important;
  aspect-ratio: 1 / 1.12;
}

@supports not (aspect-ratio: 1/1) {
  .xs-ratio {
    padding-bottom: 33% !important;
  }
  .sm-ratio {
    padding-bottom: 45% !important;
  }
  .wide-ratio {
    padding-bottom: 56.9% !important;
  }
  .md-ratio {
    padding-bottom: 66.67% !important;
  }
  .lg-ratio {
    padding-bottom: 75% !important;
  }
  .sq-ratio {
    padding-bottom: 100% !important;
  }
  .xl-ratio {
    padding-bottom: 112% !important;
  }
}

@media (max-width: 800px) {
  .card .media {
    max-width: 100%;
  }

  .card-body {
    @apply pb-5;
  }

  //
  // .card-body {
  //   padding: 1.5rem 1.5rem 1.5rem 0 !important;
  // }

  .card-col-reverse {
    flex-direction: column-reverse !important;
  }
}

@media (min-width: 800px) {
  .v-card .card-body {
    @apply pb-5;
  }

  .two-column .card.h-card {
    .media {
      height: 100%;
    }
  }

  .h-cards {
    width: 100%;

    .h-card .media-link {
      height: 100%;
    }

    &.two-column {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &.left {
        .h-card .card-body {
          order: -1;
          @apply pr-10 pl-0;
        }
      }

      &.right {
        .h-card .card-body {
          order: 1;
          @apply pr-0 pl-10;
        }
      }
    }

    &.stack {
      &.same.left {
        .h-card .card-body {
          order: -1;
          @apply pr-10 pl-0;
        }
      }

      &.same.right {
        .h-card .card-body {
          order: 1;
          @apply pr-0 pl-10;
        }
      }

      &.zig-zag.left {
        .h-card:nth-child(odd) .card-body {
          order: -1;
          @apply pr-10 pl-0;
        }

        .h-card:nth-child(even) .card-body {
          @apply pr-0 pl-10;
        }
      }

      &.zig-zag.right {
        .h-card:nth-child(even) .card-body {
          order: -1;
          @apply pr-10 pl-0;
        }

        .h-card:nth-child(odd) .card-body {
          @apply pr-0 pl-10;
        }
      }

      .h-card {
        display: flex;
        // gap: 0;
        width: 100%;

        .card-body {
          gap: 30px;
        }
      }

      .swiper-slide .h-card {
        gap: 20px !important;
      }
    }

    .h-card {
      flex-direction: row;
      width: 100%;

      .card-body {
        // justify-content: center;
        margin-bottom: 0;
        flex: 1 1 auto;
        width: 100%;
      }
    }
  }
}

@media (min-width: theme("screens.lg")) {
  .h-cards {
    /* Remove margin bottom on two-column cards */
    &.two-column {
      .card.h-card {
        width: 48%;
      }

      .card:nth-child(2n + 1):nth-last-child(-n + 2),
      .card:nth-child(2n + 1):nth-last-child(-n + 2) ~ .card {
        margin-bottom: 0 !important;
      }
    }

    &.stack {
      &.same.left {
        .h-card .card-body {
          @apply pr-10 pl-0;
        }
      }

      &.same.right {
        .h-card .card-body {
          order: 1;
          @apply pr-0 pl-10;
        }
      }

      &.zig-zag.left {
        .h-card:nth-child(odd) .card-body {
          order: -1;
          @apply pr-10 pl-0;
        }

        .h-card:nth-child(even) .card-body {
          @apply pr-0 pl-10;
        }
      }

      &.zig-zag.right {
        .h-card:nth-child(even) .card-body {
          order: -1;
          @apply pr-10 pl-0;
        }

        .h-card:nth-child(odd) .card-body {
          @apply pr-0 pl-10;
        }
      }

      .h-card {
        width: 100%;
      }
    }

    &.stack.right .h-card .card-body,
    &.stack.left .h-card .card-body {
      flex: 0 0 50%;
    }
  }
}

@media (min-width: theme("screens.xl")) {
  .h-cards {
    &.two-column .card.h-card {
      width: 48.5%;
    }
  }
}
